import { useState, useEffect, FormEvent, forwardRef, useRef } from 'react'
import cn from 'classnames'
import useFreezeBodyScroll from '@utils/use-freeze-body-scroll'
import { useUI } from '@contexts/ui.context'
import {
  InstantSearch,
  SearchBox,
  Hits,
  Configure,
  Highlight,
} from 'react-instantsearch-dom'
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter'
import {
  getCollectionName,
  typesenseInstantsearchAdapter,
  typesenseInstantsearchAdapterNumber,
} from '@utils/helper'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { convertProduct } from '@framework/utils/convert-product'
import { Product } from '@framework/types'
import { TYPESENSE_CONFIG } from '@framework/typesense'
import { useRouter as useRouterNavigation } from 'next/navigation'
import ProductCardSearchOverlay from '@components/product/product-cards/product-card-search-overlay'
import Link from '@components/ui/link'

type Props = {
  className?: string
  searchId?: string
  variant?: 'border' | 'fill'
}

let filter = 'is_active: true && search_visible:!= false'

const Hit = ({ hit }: any) => {
  return (
    <div
      key={`product--key-${hit.id}`}
      className={`relative rounded-[10px] transition-all hover:shadow bg-white`}
    >
      <ProductCardSearchOverlay
        key={`product--key-${hit.id}`}
        product={convertProduct(hit) as Product}
        className="searched-products"
      />
    </div>
  )
}

const TextHit = ({ hit }: any) => {
  return (
    <Link href={hit.slug}>
      <Highlight className="text-black" hit={hit} attribute="name" />
    </Link>
  )
}

const Search = forwardRef<HTMLDivElement, Props>(
  (
    {
      className = 'md:w-[730px] 2xl:w-[800px]',
      searchId = 'search',
      variant = 'border',
    },
    ref,
  ) => {
    const {
      displayMobileSearch,
      closeMobileSearch,
      displaySearch,
      closeSearch,
    } = useUI()
    const { t: translate } = useTranslation('common')
    const { locale } = useRouter()
    const router = useRouter()
    const [searchText, setSearchText] = useState('')
    const [resultsClass, setResultsClass] = useState('hidden')
    const [showResults, setShowResults] = useState<boolean>(false)
    const [inputFocus, setInputFocus] = useState<boolean>(false)
    const [placeholder, setPlaceholder] = useState(
      translate('query_placeholder'),
    )
    const searchContainerRef = useRef<HTMLDivElement>(null) // Add a ref for the search container

    const updatePlaceholder = () => {
      if (window.innerWidth <= 480) {
        setPlaceholder('Search FoodServiceDirect.ca') // Smaller placeholder for small screens
      } else {
        setPlaceholder(translate('query_placeholder')) // Default for larger screens
      }
    }

    useEffect(() => {
      // Set placeholder based on initial screen size
      updatePlaceholder()

      // Add event listener to handle window resizing
      window.addEventListener('resize', updatePlaceholder)

      // Clean up event listener when component unmounts
      return () => {
        window.removeEventListener('resize', updatePlaceholder)
      }
    }, [])

    useEffect(() => {
      const handleOutsideClick = (event: MouseEvent) => {
        if (
          searchContainerRef.current &&
          !searchContainerRef.current.contains(event.target as Node)
        ) {
          setShowResults(false) // Close the search results
        }
      }

      if (showResults) {
        document.addEventListener('mousedown', handleOutsideClick)
      }

      return () => {
        document.removeEventListener('mousedown', handleOutsideClick)
      }
    }, [showResults])

    useFreezeBodyScroll(
      inputFocus === true || displaySearch || displayMobileSearch,
    )
    useEffect(() => {
      setResultsClass(!showResults ? 'hidden' : 'search-results overflow-auto')
      router.events.on('routeChangeComplete', () => setShowResults(false))
      const init = async () => {
        const { Collapse, initTE } = await import('tw-elements')
        initTE({ Collapse })
      }
      init()
    }, [showResults, searchText, router])

    const handleAutoSearch = (e: FormEvent<HTMLInputElement>) => {
      setSearchText(e.currentTarget.value)
      setShowResults(
        e.currentTarget.value.length > 1 &&
          e.currentTarget.value !== e.currentTarget.placeholder,
      )
      if (
        e.currentTarget.value === '' ||
        e.currentTarget.value === e.currentTarget.placeholder
      )
        clear()
    }
    const routerN = useRouterNavigation()

    const clear = () => {
      setSearchText('')
      setShowResults(false)
      setInputFocus(false)
      closeMobileSearch()
      closeSearch()
    }

    const handleSubmit = (e: FormEvent<HTMLInputElement>) => {
      e.preventDefault()
      if (searchText.length) {
        window.location.href = `${process.env.NEXT_PUBLIC_WEBSITE_URL}/search?q=${searchText}`
      }
    }

    return (
      <>
        {/* @ts-ignore */}
        <InstantSearch
          indexName={getCollectionName(locale)}
          searchClient={
            !isNaN(Number(searchText))
              ? typesenseInstantsearchAdapterNumber.searchClient
              : typesenseInstantsearchAdapter.searchClient
          }
        >
          <Configure filters={filter} />
          <div
            ref={searchContainerRef}
            className={cn(
              'w-full transition-all duration-200 ease-in-out',
              className,
            )}
          >
            <div
              className={cn(
                'overlay cursor-pointer invisible opacity-0 flex top-0 ltr:left-0 rtl:right-0 transition-all duration-300 fixed',
                {
                  open: displayMobileSearch,
                  'input-focus-overlay-open': inputFocus === true,
                  'open-search-overlay': displaySearch,
                },
              )}
              onClick={() => clear()}
            />
            {/* End of overlay */}

            <div className="relative z-30 flex flex-col justify-center w-full shrink-0">
              <div className="flex flex-col w-full mx-auto">
                <label
                  id="headerSearchLabel"
                  htmlFor="headerSearch"
                  className="pl-1"
                >
                  {translate('query_placeholder')}
                </label>
                {/* @ts-ignore */}
                <SearchBox
                  translations={{
                    submitTitle: translate('submit_query'),
                    resetTitle: translate('clear_query'),
                    placeholder: translate('query_placeholder'),
                  }}
                  searchId={searchId}
                  name="headerSearch"
                  onChange={handleAutoSearch}
                  onSubmit={handleSubmit}
                  onReset={clear}
                  onKeyDown={(e: any) => {
                    if (e.keyCode === 13 && window && e.target.value.length)
                      window.location.href = `${process.env.NEXT_PUBLIC_WEBSITE_URL}/search?q=${e.target.value}`
                  }}
                />
              </div>
              {/* End of searchbox */}

              <div className={resultsClass}>
                {/* Desktop Hits */}
                <Hits
                  className="hidden md:block"
                  hitComponent={Hit}
                  clickAction={() => clear()}
                />
                {/* Mobile Hits */}
                <Hits
                  className="md:hidden"
                  hitComponent={TextHit}
                  clickAction={() => clear()}
                />
                <div className="w-full p-3 text-center underline searchViewMore">
                  <Link href={`/search?q=${encodeURI(searchText)}`}>
                    {translate('text-view-all-results')}
                  </Link>
                </div>
              </div>

              {/* End of search result */}
            </div>
          </div>
        </InstantSearch>
      </>
    )
  },
)

Search.displayName = 'Search'
export default Search
